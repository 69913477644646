*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

:root, [data-theme] {
  background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  color-scheme: light;
  --pf: 258.89 94.378% 40.941%;
  --sf: 314 100% 37.647%;
  --af: 174 60% 40.784%;
  --nf: 219 14.085% 22.275%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 258.89 94.378% 51.176%;
  --pc: 0 0% 100%;
  --s: 314 100% 47.059%;
  --sc: 0 0% 100%;
  --a: 174 60% 50.98%;
  --ac: 174.71 43.59% 15.294%;
  --n: 219 14.085% 27.843%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 0 0% 94.902%;
  --b3: 180 1.9608% 90%;
  --bc: 215 27.907% 16.863%;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --pf: 262.35 80.315% 40.157%;
    --sf: 315.75 70.196% 40%;
    --af: 174.69 70.335% 32.784%;
    --in: 198 93% 60%;
    --su: 158 64% 52%;
    --wa: 43 96% 56%;
    --er: 0 91% 71%;
    --inc: 198 100% 12%;
    --suc: 158 100% 10%;
    --wac: 43 100% 11%;
    --erc: 0 100% 14%;
    --rounded-box: 1rem;
    --rounded-btn: .5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: .25s;
    --animation-input: .2s;
    --btn-text-case: uppercase;
    --btn-focus-scale: .95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: .5rem;
    --p: 262.35 80.315% 50.196%;
    --pc: 0 0% 100%;
    --s: 315.75 70.196% 50%;
    --sc: 0 0% 100%;
    --a: 174.69 70.335% 40.98%;
    --ac: 0 0% 100%;
    --n: 218.18 18.033% 11.961%;
    --nf: 222.86 17.073% 8.0392%;
    --nc: 220 13.376% 69.216%;
    --b1: 220 17.647% 20%;
    --b2: 220 17.241% 17.059%;
    --b3: 218.57 17.949% 15.294%;
    --bc: 220 13.376% 69.216%;
  }
}

[data-theme="light"] {
  color-scheme: light;
  --pf: 258.89 94.378% 40.941%;
  --sf: 314 100% 37.647%;
  --af: 174 60% 40.784%;
  --nf: 219 14.085% 22.275%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 258.89 94.378% 51.176%;
  --pc: 0 0% 100%;
  --s: 314 100% 47.059%;
  --sc: 0 0% 100%;
  --a: 174 60% 50.98%;
  --ac: 174.71 43.59% 15.294%;
  --n: 219 14.085% 27.843%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 0 0% 94.902%;
  --b3: 180 1.9608% 90%;
  --bc: 215 27.907% 16.863%;
}

[data-theme="dark"] {
  color-scheme: dark;
  --pf: 262.35 80.315% 40.157%;
  --sf: 315.75 70.196% 40%;
  --af: 174.69 70.335% 32.784%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 262.35 80.315% 50.196%;
  --pc: 0 0% 100%;
  --s: 315.75 70.196% 50%;
  --sc: 0 0% 100%;
  --a: 174.69 70.335% 40.98%;
  --ac: 0 0% 100%;
  --n: 218.18 18.033% 11.961%;
  --nf: 222.86 17.073% 8.0392%;
  --nc: 220 13.376% 69.216%;
  --b1: 220 17.647% 20%;
  --b2: 220 17.241% 17.059%;
  --b3: 218.57 17.949% 15.294%;
  --bc: 220 13.376% 69.216%;
}

[data-theme="cupcake"] {
  color-scheme: light;
  --pf: 183.03 47.368% 47.216%;
  --sf: 338.25 71.429% 62.431%;
  --af: 39 84.112% 46.431%;
  --nf: 280 46.479% 11.137%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 183.03 100% 11.804%;
  --sc: 338.25 100% 15.608%;
  --ac: 39 100% 11.608%;
  --nc: 280 82.688% 82.784%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --p: 183.03 47.368% 59.02%;
  --s: 338.25 71.429% 78.039%;
  --a: 39 84.112% 58.039%;
  --n: 280 46.479% 13.922%;
  --b1: 24 33.333% 97.059%;
  --b2: 26.667 21.951% 91.961%;
  --b3: 22.5 14.286% 89.02%;
  --bc: 280 46.479% 13.922%;
  --rounded-btn: 1.9rem;
  --tab-border: 2px;
  --tab-radius: .5rem;
}

[data-theme="bumblebee"] {
  color-scheme: light;
  --pf: 41.124 74.167% 42.353%;
  --sf: 49.901 94.393% 46.431%;
  --af: 240 33.333% 11.294%;
  --nf: 240 33.333% 11.294%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --bc: 0 0% 20%;
  --ac: 240 60.274% 82.824%;
  --nc: 240 60.274% 82.824%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 41.124 74.167% 52.941%;
  --pc: 240 33.333% 14.118%;
  --s: 49.901 94.393% 58.039%;
  --sc: 240 33.333% 14.118%;
  --a: 240 33.333% 14.118%;
  --n: 240 33.333% 14.118%;
  --b1: 0 0% 100%;
}

[data-theme="emerald"] {
  color-scheme: light;
  --pf: 141.18 50% 48%;
  --sf: 218.88 96.078% 48%;
  --af: 9.8901 81.25% 44.863%;
  --nf: 219.23 20.312% 20.078%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 141.18 50% 60%;
  --pc: 151.11 28.421% 18.627%;
  --s: 218.88 96.078% 60%;
  --sc: 210 20% 98.039%;
  --a: 9.8901 81.25% 56.078%;
  --ac: 210 20% 98.039%;
  --n: 219.23 20.312% 25.098%;
  --nc: 210 20% 98.039%;
  --b1: 0 0% 100%;
  --bc: 219.23 20.312% 25.098%;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-focus-scale: 1;
}

[data-theme="corporate"] {
  color-scheme: light;
  --pf: 229.09 95.652% 51.137%;
  --sf: 214.91 26.316% 47.216%;
  --af: 154.2 49.02% 48%;
  --nf: 233.33 27.273% 10.353%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 229.09 100% 92.784%;
  --sc: 214.91 100% 11.804%;
  --ac: 154.2 100% 12%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 229.09 95.652% 63.922%;
  --s: 214.91 26.316% 59.02%;
  --a: 154.2 49.02% 60%;
  --n: 233.33 27.273% 12.941%;
  --nc: 210 38.462% 94.902%;
  --b1: 0 0% 100%;
  --bc: 233.33 27.273% 12.941%;
  --rounded-box: .25rem;
  --rounded-btn: .125rem;
  --rounded-badge: .125rem;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-focus-scale: 1;
}

[data-theme="synthwave"] {
  color-scheme: dark;
  --pf: 320.73 69.62% 55.216%;
  --sf: 197.03 86.592% 51.922%;
  --af: 48 89.041% 45.647%;
  --nf: 253.22 60.825% 15.216%;
  --b2: 253.85 59.091% 23.294%;
  --b3: 253.85 59.091% 20.965%;
  --pc: 320.73 100% 13.804%;
  --sc: 197.03 100% 12.98%;
  --ac: 48 100% 11.412%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 320.73 69.62% 69.02%;
  --s: 197.03 86.592% 64.902%;
  --a: 48 89.041% 57.059%;
  --n: 253.22 60.825% 19.02%;
  --nc: 260 60% 98.039%;
  --b1: 253.85 59.091% 25.882%;
  --bc: 260 60% 98.039%;
  --in: 199.13 86.957% 63.922%;
  --inc: 257.45 63.218% 17.059%;
  --su: 168.1 74.233% 68.039%;
  --suc: 257.45 63.218% 17.059%;
  --wa: 48 89.041% 57.059%;
  --wac: 257.45 63.218% 17.059%;
  --er: 351.85 73.636% 56.863%;
  --erc: 260 60% 98.039%;
}

[data-theme="retro"] {
  color-scheme: light;
  --pf: 2.6667 73.77% 60.863%;
  --sf: 144.62 27.273% 57.569%;
  --af: 49.024 67.213% 60.863%;
  --nf: 41.667 16.822% 33.569%;
  --inc: 221.21 100% 90.667%;
  --suc: 142.13 100% 87.255%;
  --wac: 32.133 100% 8.7451%;
  --erc: 0 100% 90.118%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 2.6667 73.77% 76.078%;
  --pc: 345 5.2632% 14.902%;
  --s: 144.62 27.273% 71.961%;
  --sc: 345 5.2632% 14.902%;
  --a: 49.024 67.213% 76.078%;
  --ac: 345 5.2632% 14.902%;
  --n: 41.667 16.822% 41.961%;
  --nc: 45 47.059% 80%;
  --b1: 45 47.059% 80%;
  --b2: 45.283 37.063% 71.961%;
  --b3: 42.188 35.955% 65.098%;
  --bc: 345 5.2632% 14.902%;
  --in: 221.21 83.193% 53.333%;
  --su: 142.13 76.216% 36.275%;
  --wa: 32.133 94.619% 43.725%;
  --er: 0 72.222% 50.588%;
  --rounded-box: .4rem;
  --rounded-btn: .4rem;
  --rounded-badge: .4rem;
}

[data-theme="cyberpunk"] {
  color-scheme: light;
  --pf: 344.78 100% 58.353%;
  --sf: 195.12 80.392% 56%;
  --af: 276 74.324% 56.784%;
  --nf: 57.273 100% 10.353%;
  --b2: 56 100% 45%;
  --b3: 56 100% 40.5%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --bc: 56 100% 10%;
  --pc: 344.78 100% 14.588%;
  --sc: 195.12 100% 14%;
  --ac: 276 100% 14.196%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 344.78 100% 72.941%;
  --s: 195.12 80.392% 70%;
  --a: 276 74.324% 70.98%;
  --n: 57.273 100% 12.941%;
  --nc: 56 100% 50%;
  --b1: 56 100% 50%;
  --rounded-box: 0;
  --rounded-btn: 0;
  --rounded-badge: 0;
  --tab-radius: 0;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

[data-theme="valentine"] {
  color-scheme: light;
  --pf: 353.23 73.81% 53.647%;
  --sf: 254.12 86.441% 61.49%;
  --af: 181.41 55.556% 56%;
  --nf: 336 42.857% 38.431%;
  --b2: 318.46 46.429% 80.118%;
  --b3: 318.46 46.429% 72.106%;
  --pc: 353.23 100% 13.412%;
  --sc: 254.12 100% 15.373%;
  --ac: 181.41 100% 14%;
  --inc: 221.21 100% 90.667%;
  --suc: 142.13 100% 87.255%;
  --wac: 32.133 100% 8.7451%;
  --erc: 0 100% 90.118%;
  --rounded-box: 1rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 353.23 73.81% 67.059%;
  --s: 254.12 86.441% 76.863%;
  --a: 181.41 55.556% 70%;
  --n: 336 42.857% 48.039%;
  --nc: 318.46 46.429% 89.02%;
  --b1: 318.46 46.429% 89.02%;
  --bc: 343.64 38.462% 28.039%;
  --in: 221.21 83.193% 53.333%;
  --su: 142.13 76.216% 36.275%;
  --wa: 32.133 94.619% 43.725%;
  --er: 0 72.222% 50.588%;
  --rounded-btn: 1.9rem;
}

[data-theme="halloween"] {
  color-scheme: dark;
  --pf: 31.927 89.344% 41.725%;
  --sf: 271.22 45.794% 33.569%;
  --af: 91.071 100% 26.353%;
  --nf: 180 3.5714% 8.7843%;
  --b2: 0 0% 11.647%;
  --b3: 0 0% 10.482%;
  --bc: 0 0% 82.588%;
  --sc: 271.22 100% 88.392%;
  --ac: 91.071 100% 6.5882%;
  --nc: 180 4.8458% 82.196%;
  --inc: 221.21 100% 90.667%;
  --suc: 142.13 100% 87.255%;
  --wac: 32.133 100% 8.7451%;
  --erc: 0 100% 90.118%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 31.927 89.344% 52.157%;
  --pc: 180 7.3171% 8.0392%;
  --s: 271.22 45.794% 41.961%;
  --a: 91.071 100% 32.941%;
  --n: 180 3.5714% 10.98%;
  --b1: 0 0% 12.941%;
  --in: 221.21 83.193% 53.333%;
  --su: 142.13 76.216% 36.275%;
  --wa: 32.133 94.619% 43.725%;
  --er: 0 72.222% 50.588%;
}

[data-theme="garden"] {
  color-scheme: light;
  --pf: 138.86 15.982% 34.353%;
  --sf: 96.923 37.143% 74.51%;
  --af: 0 67.742% 75.137%;
  --nf: 0 3.9106% 28.078%;
  --b2: 0 4.3478% 81.882%;
  --b3: 0 4.3478% 73.694%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 138.86 100% 88.588%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 138.86 15.982% 42.941%;
  --s: 96.923 37.143% 93.137%;
  --sc: 96 32.468% 15.098%;
  --a: 0 67.742% 93.922%;
  --ac: 0 21.951% 16.078%;
  --n: 0 3.9106% 35.098%;
  --nc: 0 4.3478% 90.98%;
  --b1: 0 4.3478% 90.98%;
  --bc: 0 3.2258% 6.0784%;
}

[data-theme="forest"] {
  color-scheme: dark;
  --pf: 141.04 71.963% 33.569%;
  --sf: 140.98 74.694% 38.431%;
  --af: 35.148 68.98% 41.569%;
  --nf: 0 9.6774% 4.8627%;
  --b2: 0 12.195% 7.2353%;
  --b3: 0 12.195% 6.5118%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --bc: 0 11.727% 81.608%;
  --sc: 140.98 100% 9.6078%;
  --ac: 35.148 100% 10.392%;
  --nc: 0 6.8894% 81.216%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 141.04 71.963% 41.961%;
  --pc: 140.66 100% 88.039%;
  --s: 140.98 74.694% 48.039%;
  --a: 35.148 68.98% 51.961%;
  --n: 0 9.6774% 6.0784%;
  --b1: 0 12.195% 8.0392%;
  --rounded-btn: 1.9rem;
}

[data-theme="aqua"] {
  color-scheme: dark;
  --pf: 181.79 92.857% 39.529%;
  --sf: 274.41 30.909% 45.49%;
  --af: 47.059 100% 64%;
  --nf: 205.4 53.725% 40%;
  --b2: 218.61 52.511% 38.647%;
  --b3: 218.61 52.511% 34.782%;
  --bc: 218.61 100% 88.588%;
  --sc: 274.41 100% 91.373%;
  --ac: 47.059 100% 16%;
  --nc: 205.4 100% 90%;
  --inc: 221.21 100% 90.667%;
  --suc: 142.13 100% 87.255%;
  --wac: 32.133 100% 8.7451%;
  --erc: 0 100% 90.118%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 181.79 92.857% 49.412%;
  --pc: 181.41 100% 16.667%;
  --s: 274.41 30.909% 56.863%;
  --a: 47.059 100% 80%;
  --n: 205.4 53.725% 50%;
  --b1: 218.61 52.511% 42.941%;
  --in: 221.21 83.193% 53.333%;
  --su: 142.13 76.216% 36.275%;
  --wa: 32.133 94.619% 43.725%;
  --er: 0 72.222% 50.588%;
}

[data-theme="lofi"] {
  color-scheme: light;
  --pf: 0 0% 4.0784%;
  --sf: 0 1.9608% 8%;
  --af: 0 0% 11.922%;
  --nf: 0 0% 0%;
  --btn-text-case: uppercase;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 0 0% 5.098%;
  --pc: 0 0% 100%;
  --s: 0 1.9608% 10%;
  --sc: 0 0% 100%;
  --a: 0 0% 14.902%;
  --ac: 0 0% 100%;
  --n: 0 0% 0%;
  --nc: 0 0% 100%;
  --b1: 0 0% 100%;
  --b2: 0 0% 94.902%;
  --b3: 0 1.9608% 90%;
  --bc: 0 0% 0%;
  --in: 212.35 100% 47.647%;
  --inc: 0 0% 100%;
  --su: 136.84 72.152% 46.471%;
  --suc: 0 0% 100%;
  --wa: 4.5614 100% 66.471%;
  --wac: 0 0% 100%;
  --er: 325.05 77.6% 49.02%;
  --erc: 0 0% 100%;
  --rounded-box: .25rem;
  --rounded-btn: .125rem;
  --rounded-badge: .125rem;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-focus-scale: 1;
  --tab-radius: 0;
}

[data-theme="pastel"] {
  color-scheme: light;
  --pf: 283.64 21.569% 64%;
  --sf: 351.63 70.492% 70.431%;
  --af: 158.49 54.639% 64.784%;
  --nf: 198.62 43.719% 48.784%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --bc: 0 0% 20%;
  --pc: 283.64 59.314% 16%;
  --sc: 351.63 100% 17.608%;
  --ac: 158.49 100% 16.196%;
  --nc: 198.62 100% 12.196%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 283.64 21.569% 80%;
  --s: 351.63 70.492% 88.039%;
  --a: 158.49 54.639% 80.98%;
  --n: 198.62 43.719% 60.98%;
  --b1: 0 0% 100%;
  --b2: 210 20% 98.039%;
  --b3: 216 12.195% 83.922%;
  --rounded-btn: 1.9rem;
}

[data-theme="fantasy"] {
  color-scheme: light;
  --pf: 296.04 82.813% 20.078%;
  --sf: 200 100% 29.647%;
  --af: 30.894 94.378% 40.941%;
  --nf: 215 27.907% 13.49%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
  --pc: 296.04 100% 85.02%;
  --sc: 200 100% 87.412%;
  --ac: 30.894 100% 10.235%;
  --nc: 215 62.264% 83.373%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 296.04 82.813% 25.098%;
  --s: 200 100% 37.059%;
  --a: 30.894 94.378% 51.176%;
  --n: 215 27.907% 16.863%;
  --b1: 0 0% 100%;
  --bc: 215 27.907% 16.863%;
}

[data-theme="wireframe"] {
  color-scheme: light;
  --pf: 0 0% 57.725%;
  --sf: 0 0% 57.725%;
  --af: 0 0% 57.725%;
  --nf: 0 0% 73.725%;
  --bc: 0 0% 20%;
  --pc: 0 0% 14.431%;
  --sc: 0 0% 14.431%;
  --ac: 0 0% 14.431%;
  --nc: 0 0% 18.431%;
  --inc: 240 100% 90%;
  --suc: 120 100% 85.02%;
  --wac: 60 100% 10%;
  --erc: 0 100% 90%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 0 0% 72.157%;
  --s: 0 0% 72.157%;
  --a: 0 0% 72.157%;
  --n: 0 0% 92.157%;
  --b1: 0 0% 100%;
  --b2: 0 0% 93.333%;
  --b3: 0 0% 86.667%;
  --in: 240 100% 50%;
  --su: 120 100% 25.098%;
  --wa: 60 30.196% 50%;
  --er: 0 100% 50%;
  --rounded-box: .2rem;
  --rounded-btn: .2rem;
  --rounded-badge: .2rem;
  --tab-radius: .2rem;
  font-family: Chalkboard, comic sans ms, sanssecondaryerif;
}

[data-theme="black"] {
  color-scheme: dark;
  --pf: 0 1.9608% 16%;
  --sf: 0 1.9608% 16%;
  --af: 0 1.9608% 16%;
  --bc: 0 0% 80%;
  --pc: 0 5.3922% 84%;
  --sc: 0 5.3922% 84%;
  --ac: 0 5.3922% 84%;
  --nc: 0 2.5404% 83.02%;
  --inc: 240 100% 90%;
  --suc: 120 100% 85.02%;
  --wac: 60 100% 10%;
  --erc: 0 100% 90%;
  --border-btn: 1px;
  --tab-border: 1px;
  --p: 0 1.9608% 20%;
  --s: 0 1.9608% 20%;
  --a: 0 1.9608% 20%;
  --b1: 0 0% 0%;
  --b2: 0 0% 5.098%;
  --b3: 0 1.9608% 10%;
  --n: 0 1.2987% 15.098%;
  --nf: 0 1.9608% 20%;
  --in: 240 100% 50%;
  --su: 120 100% 25.098%;
  --wa: 60 100% 50%;
  --er: 0 100% 50%;
  --rounded-box: 0;
  --rounded-btn: 0;
  --rounded-badge: 0;
  --animation-btn: 0;
  --animation-input: 0;
  --btn-text-case: lowercase;
  --btn-focus-scale: 1;
  --tab-radius: 0;
}

[data-theme="luxury"] {
  color-scheme: dark;
  --pf: 0 0% 80%;
  --sf: 218.4 54.348% 14.431%;
  --af: 318.62 21.805% 20.863%;
  --nf: 270 4.3478% 7.2157%;
  --pc: 0 0% 20%;
  --sc: 218.4 100% 83.608%;
  --ac: 318.62 84.615% 85.216%;
  --inc: 202.35 100% 14%;
  --suc: 89.007 100% 10.392%;
  --wac: 53.906 100% 12.706%;
  --erc: 0 100% 14.353%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 0 0% 100%;
  --s: 218.4 54.348% 18.039%;
  --a: 318.62 21.805% 26.078%;
  --n: 270 4.3478% 9.0196%;
  --nc: 37.083 67.29% 58.039%;
  --b1: 240 10% 3.9216%;
  --b2: 270 4.3478% 9.0196%;
  --b3: 270 2.1739% 18.039%;
  --bc: 37.083 67.29% 58.039%;
  --in: 202.35 100% 70%;
  --su: 89.007 61.633% 51.961%;
  --wa: 53.906 68.817% 63.529%;
  --er: 0 100% 71.765%;
}

[data-theme="dracula"] {
  color-scheme: dark;
  --pf: 325.52 100% 58.98%;
  --sf: 264.71 89.474% 62.118%;
  --af: 31.02 100% 56.941%;
  --nf: 229.57 15.033% 24%;
  --b2: 231.43 14.894% 16.588%;
  --b3: 231.43 14.894% 14.929%;
  --pc: 325.52 100% 14.745%;
  --sc: 264.71 100% 15.529%;
  --ac: 31.02 100% 14.235%;
  --nc: 229.57 70.868% 86%;
  --inc: 190.53 100% 15.373%;
  --suc: 135.18 100% 12.941%;
  --wac: 64.909 100% 15.294%;
  --erc: 0 100% 93.333%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 325.52 100% 73.725%;
  --s: 264.71 89.474% 77.647%;
  --a: 31.02 100% 71.176%;
  --n: 229.57 15.033% 30%;
  --b1: 231.43 14.894% 18.431%;
  --bc: 60 30% 96.078%;
  --in: 190.53 96.61% 76.863%;
  --su: 135.18 94.444% 64.706%;
  --wa: 64.909 91.667% 76.471%;
  --er: 0 100% 66.667%;
}

[data-theme="cmyk"] {
  color-scheme: light;
  --pf: 202.72 83.251% 48.157%;
  --sf: 335.25 77.67% 47.686%;
  --af: 56.195 100% 47.843%;
  --nf: 0 0% 8.1569%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --bc: 0 0% 20%;
  --pc: 202.72 100% 12.039%;
  --sc: 335.25 100% 91.922%;
  --ac: 56.195 100% 11.961%;
  --nc: 0 0% 82.039%;
  --inc: 192.2 100% 10.431%;
  --suc: 291.06 100% 87.608%;
  --wac: 25.027 100% 11.333%;
  --erc: 3.956 100% 91.137%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 202.72 83.251% 60.196%;
  --s: 335.25 77.67% 59.608%;
  --a: 56.195 100% 59.804%;
  --n: 0 0% 10.196%;
  --b1: 0 0% 100%;
  --in: 192.2 48.361% 52.157%;
  --su: 291.06 48.454% 38.039%;
  --wa: 25.027 84.615% 56.667%;
  --er: 3.956 80.531% 55.686%;
}

[data-theme="autumn"] {
  color-scheme: light;
  --pf: 344.23 95.804% 22.431%;
  --sf: .44444 63.38% 46.588%;
  --af: 27.477 56.021% 50.039%;
  --nf: 22.105 17.117% 34.824%;
  --b2: 0 0% 85.059%;
  --b3: 0 0% 76.553%;
  --bc: 0 0% 18.902%;
  --pc: 344.23 100% 85.608%;
  --sc: .44444 100% 91.647%;
  --ac: 27.477 100% 12.51%;
  --nc: 22.105 100% 88.706%;
  --inc: 186.94 100% 9.9216%;
  --suc: 164.59 100% 8.6275%;
  --wac: 30.141 100% 9.9216%;
  --erc: 353.6 100% 89.765%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 344.23 95.804% 28.039%;
  --s: .44444 63.38% 58.235%;
  --a: 27.477 56.021% 62.549%;
  --n: 22.105 17.117% 43.529%;
  --b1: 0 0% 94.51%;
  --in: 186.94 47.826% 49.608%;
  --su: 164.59 33.636% 43.137%;
  --wa: 30.141 84.19% 49.608%;
  --er: 353.6 79.116% 48.824%;
}

[data-theme="business"] {
  color-scheme: dark;
  --pf: 210 64.103% 24.471%;
  --sf: 200 12.931% 43.608%;
  --af: 12.515 79.512% 47.843%;
  --nf: 212.73 13.58% 12.706%;
  --b2: 0 0% 11.294%;
  --b3: 0 0% 10.165%;
  --bc: 0 0% 82.51%;
  --pc: 210 100% 86.118%;
  --sc: 200 100% 10.902%;
  --ac: 12.515 100% 11.961%;
  --nc: 212.73 28.205% 83.176%;
  --inc: 199.15 100% 88.353%;
  --suc: 144 100% 11.137%;
  --wac: 39.231 100% 12.078%;
  --erc: 6.3415 100% 88.667%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 210 64.103% 30.588%;
  --s: 200 12.931% 54.51%;
  --a: 12.515 79.512% 59.804%;
  --n: 212.73 13.58% 15.882%;
  --b1: 0 0% 12.549%;
  --in: 199.15 100% 41.765%;
  --su: 144 30.973% 55.686%;
  --wa: 39.231 64.356% 60.392%;
  --er: 6.3415 55.656% 43.333%;
  --rounded-box: .25rem;
  --rounded-btn: .125rem;
  --rounded-badge: .125rem;
}

[data-theme="acid"] {
  color-scheme: light;
  --pf: 302.59 100% 40%;
  --sf: 27.294 100% 40%;
  --af: 72 98.425% 40.157%;
  --nf: 238.42 43.182% 13.804%;
  --b2: 0 0% 88.235%;
  --b3: 0 0% 79.412%;
  --bc: 0 0% 19.608%;
  --pc: 302.59 100% 90%;
  --sc: 27.294 100% 10%;
  --ac: 72 100% 10.039%;
  --nc: 238.42 99.052% 83.451%;
  --inc: 209.85 100% 11.569%;
  --suc: 148.87 100% 11.608%;
  --wac: 52.574 100% 11.451%;
  --erc: .78261 100% 89.02%;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 302.59 100% 50%;
  --s: 27.294 100% 50%;
  --a: 72 98.425% 50.196%;
  --n: 238.42 43.182% 17.255%;
  --b1: 0 0% 98.039%;
  --in: 209.85 91.628% 57.843%;
  --su: 148.87 49.533% 58.039%;
  --wa: 52.574 92.661% 57.255%;
  --er: .78261 100% 45.098%;
  --rounded-box: 1.25rem;
  --rounded-btn: 1rem;
  --rounded-badge: 1rem;
}

[data-theme="lemonade"] {
  color-scheme: light;
  --pf: 88.8 96.154% 24.471%;
  --sf: 60 80.952% 43.765%;
  --af: 62.553 79.661% 70.745%;
  --nf: 238.42 43.182% 13.804%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --bc: 0 0% 20%;
  --pc: 88.8 100% 86.118%;
  --sc: 60 100% 10.941%;
  --ac: 62.553 100% 17.686%;
  --nc: 238.42 99.052% 83.451%;
  --inc: 191.61 79.118% 16.902%;
  --suc: 74.458 100% 15.725%;
  --wac: 50.182 100% 15.059%;
  --erc: .98361 100% 16.588%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 88.8 96.154% 30.588%;
  --s: 60 80.952% 54.706%;
  --a: 62.553 79.661% 88.431%;
  --n: 238.42 43.182% 17.255%;
  --b1: 0 0% 100%;
  --in: 191.61 39.241% 84.51%;
  --su: 74.458 76.147% 78.627%;
  --wa: 50.182 87.302% 75.294%;
  --er: .98361 70.115% 82.941%;
}

[data-theme="night"] {
  color-scheme: dark;
  --pf: 198.44 93.204% 47.686%;
  --sf: 234.45 89.474% 59.137%;
  --af: 328.85 85.621% 56%;
  --b2: 222.22 47.368% 10.059%;
  --b3: 222.22 47.368% 9.0529%;
  --bc: 222.22 65.563% 82.235%;
  --pc: 198.44 100% 11.922%;
  --sc: 234.45 100% 14.784%;
  --ac: 328.85 100% 14%;
  --nc: 217.24 75.772% 83.49%;
  --inc: 198.46 100% 9.6078%;
  --suc: 172.46 100% 10.078%;
  --wac: 40.61 100% 12.706%;
  --erc: 350.94 100% 14.235%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 198.44 93.204% 59.608%;
  --s: 234.45 89.474% 73.922%;
  --a: 328.85 85.621% 70%;
  --n: 217.24 32.584% 17.451%;
  --nf: 217.06 30.357% 21.961%;
  --b1: 222.22 47.368% 11.176%;
  --in: 198.46 90.204% 48.039%;
  --su: 172.46 66.008% 50.392%;
  --wa: 40.61 88.172% 63.529%;
  --er: 350.94 94.558% 71.176%;
}

[data-theme="coffee"] {
  color-scheme: dark;
  --pf: 29.583 66.667% 46.118%;
  --sf: 182.4 24.752% 15.843%;
  --af: 194.19 74.4% 19.608%;
  --nf: 300 20% 4.7059%;
  --b2: 306 18.519% 9.5294%;
  --b3: 306 18.519% 8.5765%;
  --pc: 29.583 100% 11.529%;
  --sc: 182.4 67.237% 83.961%;
  --ac: 194.19 100% 84.902%;
  --nc: 300 13.75% 81.176%;
  --inc: 171.15 100% 13.451%;
  --suc: 92.5 100% 12.471%;
  --wac: 43.125 100% 13.725%;
  --erc: 9.7561 100% 14.941%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 29.583 66.667% 57.647%;
  --s: 182.4 24.752% 19.804%;
  --a: 194.19 74.4% 24.51%;
  --n: 300 20% 5.8824%;
  --b1: 306 18.519% 10.588%;
  --bc: 36.667 8.3333% 42.353%;
  --in: 171.15 36.527% 67.255%;
  --su: 92.5 25% 62.353%;
  --wa: 43.125 100% 68.627%;
  --er: 9.7561 95.349% 74.706%;
}

[data-theme="winter"] {
  color-scheme: light;
  --pf: 211.79 100% 40.627%;
  --sf: 246.92 47.273% 34.51%;
  --af: 310.41 49.388% 41.569%;
  --nf: 217.02 92.157% 8%;
  --pc: 211.79 100% 90.157%;
  --sc: 246.92 100% 88.627%;
  --ac: 310.41 100% 90.392%;
  --nc: 217.02 100% 82%;
  --inc: 191.54 100% 15.608%;
  --suc: 181.5 100% 13.255%;
  --wac: 32.308 100% 16.706%;
  --erc: 0 100% 14.431%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --p: 211.79 100% 50.784%;
  --s: 246.92 47.273% 43.137%;
  --a: 310.41 49.388% 51.961%;
  --n: 217.02 92.157% 10%;
  --b1: 0 0% 100%;
  --b2: 216.92 100% 97.451%;
  --b3: 218.82 43.59% 92.353%;
  --bc: 214.29 30.061% 31.961%;
  --in: 191.54 92.857% 78.039%;
  --su: 181.5 46.512% 66.275%;
  --wa: 32.308 61.905% 83.529%;
  --er: 0 63.38% 72.157%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.carousel {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  overflow-x: scroll;
}

.carousel-item {
  box-sizing: content-box;
  scroll-snap-align: start;
  flex: none;
  display: flex;
}

.carousel-center .carousel-item {
  scroll-snap-align: center;
}

.carousel-end .carousel-item {
  scroll-snap-align: end;
}

.footer {
  width: 100%;
  grid-auto-flow: row;
  place-items: start;
  gap: 2.5rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: grid;
}

.footer > * {
  place-items: start;
  gap: .5rem;
  display: grid;
}

@media (min-width: 48rem) {
  .footer {
    grid-auto-flow: column;
  }

  .footer-center {
    grid-auto-flow: row dense;
  }
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.progress {
  width: 100%;
  appearance: none;
  height: .5rem;
  border-radius: var(--rounded-box, 1rem);
  position: relative;
  overflow: hidden;
}

.swap {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.swap > * {
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.swap input {
  appearance: none;
}

.swap .swap-on, .swap .swap-indeterminate, .swap input:indeterminate ~ .swap-on, .swap input:checked ~ .swap-off, .swap.swap-active .swap-off, .swap input:indeterminate ~ .swap-off {
  opacity: 0;
}

.swap input:checked ~ .swap-on, .swap-active .swap-on, .swap input:indeterminate ~ .swap-indeterminate {
  opacity: 1;
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .95));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.carousel::-webkit-scrollbar {
  display: none;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.progress::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
}

.progress:indeterminate:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  content: "";
  width: 33.3333%;
  border-radius: var(--rounded-box, 1rem);
  animation: 5s ease-in-out infinite progress-loading;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -40%;
}

.progress::-webkit-progress-bar {
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  border-radius: var(--rounded-box, 1rem);
}

.progress::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
}

@keyframes progress-loading {
  50% {
    left: 107%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.left-5 {
  left: 1.25rem;
}

.right-5 {
  right: 1.25rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[8vw\] {
  margin-left: 8vw;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[96vh\] {
  margin-top: 96vh;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-32 {
  height: 8rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[4vh\] {
  height: 4vh;
}

.h-\[80vh\] {
  height: 80vh;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-32 {
  width: 8rem;
}

.w-4\/5 {
  width: 80%;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-96 {
  width: 24rem;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[20vw\] {
  width: 20vw;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[30vw\] {
  width: 30vw;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[84vw\] {
  width: 84vw;
}

.w-\[8vw\] {
  width: 8vw;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.list-disc {
  list-style-type: disc;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-0 {
  gap: 0;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-20 {
  gap: 5rem;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-black-sc {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-gray-sc {
  --tw-border-opacity: 1;
  border-color: rgb(36 35 35 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow-sc {
  --tw-border-opacity: 1;
  border-color: rgb(0 207 193 / var(--tw-border-opacity));
}

.border-opacity-60 {
  --tw-border-opacity: .6;
}

.bg-black-sc {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-gray-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 253 250 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-pr {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.bg-gray-sc {
  --tw-bg-opacity: 1;
  background-color: rgb(36 35 35 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-sc {
  --tw-bg-opacity: 1;
  background-color: rgb(0 207 193 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-opacity-95 {
  --tw-bg-opacity: .95;
}

.bg-project-1 {
  background-image: url("skibua.5036bb10.gif");
}

.bg-project-2 {
  background-image: url("vildskud.ff1c79ee.gif");
}

.bg-project-3 {
  background-image: url("lederne.10042d93.gif");
}

.bg-project-4 {
  background-image: url("louisiana.83077a0e.gif");
}

.bg-project-5 {
  background-image: url("motionvisuals.456be2a6.gif");
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-\[4vh\] {
  padding-bottom: 4vh;
}

.pl-2 {
  padding-left: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.font-franklin {
  font-family: Franklin, sans-serif;
}

.font-shrikhand {
  font-family: Shrikhand, sans-serif;
}

.text-2xl {
  font-size: 1rem;
}

.text-5xl {
  font-size: 2rem;
}

.text-6xl {
  font-size: 4rem;
}

.text-8xl {
  font-size: 5rem;
}

.text-9xl {
  font-size: 2.3rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.1rem;
}

.text-sm {
  font-size: .8rem;
}

.text-xl {
  font-size: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[1\.05\] {
  line-height: 1.05;
}

.leading-\[1\], .leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.text-black-sc {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-2 {
  --tw-text-opacity: 1;
  color: rgb(198 198 198 / var(--tw-text-opacity));
}

.text-gray-sc {
  --tw-text-opacity: 1;
  color: rgb(36 35 35 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-sc {
  --tw-text-opacity: 1;
  color: rgb(0 207 193 / var(--tw-text-opacity));
}

.decoration-yellow-sc {
  text-decoration-color: #00cfc1;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.text-black {
  color: #000;
}

.border-black {
  border-color: #000;
}

.bg-black {
  color: #000;
}

.text-white {
  color: #fff;
}

.bg-black {
  background-color: #000;
}

.outline-title {
  color: #000;
  text-shadow: -1px -1px #fff, 1px -1px #fff, -1px 1px #fff, 1px 1px #fff;
  font-size: 185px;
}

.leading-trim {
  vertical-align: middle;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
}

.animate-right {
  animation: 20s linear infinite animation;
}

@keyframes animation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(15deg);
  }
}

::selection {
  color: #fff;
  background: #00cfc1;
}

.slide-in-top {
  -webkit-animation: .5s cubic-bezier(.215, .355, .35, 1) both slide-in-top;
  animation: .5s cubic-bezier(.215, .355, .61, 1) both slide-in-top;
}

@-webkit-keyframes slide-in-top {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-top {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-top2 {
  -webkit-animation: .6s cubic-bezier(.215, .355, .35, 1) both slide-in-top;
  animation: .6s cubic-bezier(.215, .355, .61, 1) both slide-in-top;
}

@keyframes slide-in-top2 {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-top3 {
  -webkit-animation: .7s cubic-bezier(.215, .355, .35, 1) both slide-in-top;
  animation: .7s cubic-bezier(.215, .355, .61, 1) both slide-in-top;
}

@keyframes slide-in-top3 {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-out-top {
  animation: 1s cubic-bezier(.215, .61, .355, 1) both slide-in-top;
}

@keyframes slide-out-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-500px);
  }
}

.opacity-up {
  animation: 1.5s cubic-bezier(.215, .61, .355, 1) both opacity-up;
}

@-webkit-keyframes opacity-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slide-from-left {
  animation: 1s cubic-bezier(.215, .61, .355, 1) both slide-from-left;
}

@keyframes slide-from-left {
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-from-right {
  animation: 1s cubic-bezier(.215, .61, .355, 1) both slide-from-right;
}

@keyframes slide-from-right {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.outline-w {
  text-shadow: -1px -1px #fff, 1px -1px #fff, -1px 1px #fff, 1px 1px #000;
  color: #000;
  opacity: 85;
}

.text-shadow-pop-top {
  animation: .6s both text-shadow-pop-top;
}

.pp-rotate {
  animation: 5s linear infinite rotate-pp;
}

@-webkit-keyframes rotate-pp {
  0% {
    rotate: none;
  }

  50% {
    rotate: 180deg;
  }

  100% {
    rotate: 360deg;
  }
}

.font-change {
  animation: 2s linear infinite font-change;
}

@-webkit-keyframes font-change {
  50% {
    font-family: Shrikhand;
  }
}

.hover\:ml-7:hover {
  margin-left: 1.75rem;
}

.hover\:mt-3:hover {
  margin-top: .75rem;
}

.hover\:mt-7:hover {
  margin-top: 1.75rem;
}

.hover\:h-14:hover {
  height: 3.5rem;
}

.hover\:w-14:hover {
  width: 3.5rem;
}

.hover\:w-9:hover {
  width: 2.25rem;
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-none:hover {
  border-style: none;
}

.hover\:border-yellow-sc:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 207 193 / var(--tw-border-opacity));
}

.hover\:bg-black-sc:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-sc:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 207 193 / var(--tw-bg-opacity));
}

.hover\:pl-2:hover {
  padding-left: .5rem;
}

.hover\:text-base:hover {
  font-size: 1rem;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-yellow-sc:hover {
  --tw-text-opacity: 1;
  color: rgb(0 207 193 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

@media (min-width: 150px) {
  .sm\:m-10 {
    margin: 2.5rem;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-20 {
    height: 5rem;
  }

  .sm\:h-36 {
    height: 9rem;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-\[100vw\] {
    width: 100vw;
  }

  .sm\:w-\[33\.3vw\] {
    width: 33.3vw;
  }

  .sm\:w-\[84vw\] {
    width: 84vw;
  }

  .sm\:w-\[96vw\] {
    width: 96vw;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-screen {
    width: 100vw;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:bg-gray-1 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 253 250 / var(--tw-bg-opacity));
  }

  .sm\:bg-opacity-75 {
    --tw-bg-opacity: .75;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:text-10xl {
    font-size: 7rem;
  }

  .sm\:text-6xl {
    font-size: 4rem;
  }

  .sm\:text-8xl {
    font-size: 5rem;
  }
}

@media (min-width: 768px) {
  .md\:text-10xl {
    font-size: 7rem;
  }

  .md\:text-8xl {
    font-size: 5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:m-0 {
    margin: 0;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-52 {
    width: 13rem;
  }

  .lg\:w-\[36vw\] {
    width: 36vw;
  }

  .lg\:w-\[40vw\] {
    width: 40vw;
  }

  .lg\:w-\[42vw\] {
    width: 42vw;
  }

  .lg\:w-\[44vw\] {
    width: 44vw;
  }

  .lg\:w-\[45\.5vw\] {
    width: 45.5vw;
  }

  .lg\:w-\[46vw\] {
    width: 46vw;
  }

  .lg\:w-\[48vw\] {
    width: 48vw;
  }

  .lg\:w-\[70vw\] {
    width: 70vw;
  }

  .lg\:w-\[84vw\] {
    width: 84vw;
  }

  .lg\:w-\[9vw\] {
    width: 9vw;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:text-10xl {
    font-size: 7rem;
  }

  .lg\:text-11xl {
    font-size: 6rem;
  }

  .lg\:text-12xl {
    font-size: 14rem;
  }

  .lg\:text-8xl {
    font-size: 5rem;
  }
}

/*# sourceMappingURL=index.1cde40e1.css.map */
