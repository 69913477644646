@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {

 
    }

    .text-black {
      color:black;
    }
    .border-black {
      border-color:black;
    }

   .bg-black {
    color:black;
    }

    .text-white {
      color:white;
    }
    .bg-black {
      background-color: black;
    }
    @layer base {
      .font-outline-2 {
        -webkit-text-stroke: 2px white;
      }
      .font-outline-4 {
        -webkit-text-stroke: 4px white;
      }
    }
    .outline-title {
      color: #000000 ;
      text-shadow:
       -1px -1px 0 #fff,  
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
         1px 1px 0 #fff;
           font-size: 185px;
      }
      .leading-trim {
        @apply font-sans text-base align-middle;
      }
     .animate-right {
      animation: animation 20s linear infinite;
    }
      @keyframes animation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(15deg);
        }
      }

      ::selection {
        background: #00CFC1;
        color: #ffffff;
  
      }


      .slide-in-top {
        -webkit-animation: slide-in-top .5s cubic-bezier(0.215, 0.355, 0.35, 1.000) both;
                animation: slide-in-top .5s cubic-bezier(0.215, 0.355, 0.610, 1.000) both;
      }
      
      @-webkit-keyframes slide-in-top {
        0% {
          -webkit-transform: translateY(-200px);
                  transform: translateY(-200px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-top {
        0% {
          -webkit-transform: translateY(-50px);
                  transform: translateY(-50px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }

      .slide-in-top2 {
        -webkit-animation: slide-in-top .6s cubic-bezier(0.215, 0.355, 0.35, 1.000) both;
                animation: slide-in-top .6s cubic-bezier(0.215, 0.355, 0.610, 1.000) both;
      }
      
      @-webkit-keyframes slide-in-top2 {
        0% {
          -webkit-transform: translateY(-50px);
                  transform: translateY(-50px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-top2 {
        0% {
          -webkit-transform: translateY(-50px);
                  transform: translateY(-50px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }

      .slide-in-top3 {
        -webkit-animation: slide-in-top .7s cubic-bezier(0.215, 0.355, 0.35, 1.000) both;
                animation: slide-in-top .7s cubic-bezier(0.215, 0.355, 0.610, 1.000) both;
      }
      
      @-webkit-keyframes slide-in-top3 {
        0% {
          -webkit-transform: translateY(-50px);
                  transform: translateY(-50px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-top3 {
        0% {
          -webkit-transform: translateY(-50px);
                  transform: translateY(-50px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }

      .slide-out-top {
        -webkit-animation: slide-in-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: slide-in-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      }
      
      @-webkit-keyframes slide-out-top {
        0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
        }
        100% {
         
          -webkit-transform: translateY(-500px);
          transform: translateY(-500px);
  opacity: 0;
        }
      }
      @keyframes slide-out-top {
        0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
        }
        100% {
      
          -webkit-transform: translateY(-500px);
          transform: translateY(-500px);
  opacity: 0;
        }
      }


      .opacity-up {
        -webkit-animation: opacity-up 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: opacity-up 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      }
      
      @-webkit-keyframes opacity-up {
        0% {
  
  opacity: 0;
        }
        100% {
       
  opacity: 1;
        }
      }
  

      .slide-from-left {
        -webkit-animation: slide-from-left 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: slide-from-left 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      }
      
      @-webkit-keyframes  slide-from-left {
        0% {
          -webkit-transform: translateX(-500px);
          transform: translateX(-500px);
        
  opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
         
  opacity: 1;
        }
      }
      @keyframes slide-from-left {
        0% {
          -webkit-transform: translateX(-500px);
          transform: translateX(-500px);
        
  opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
         
  opacity: 1;
        }
      }

      .slide-from-right {
        -webkit-animation: slide-from-right 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: slide-from-right 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      }
      
      @-webkit-keyframes  slide-from-right {
        0% {
          -webkit-transform: translateX(500px);
          transform: translateX(500px);
        
  opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
         
  opacity: 1;
        }
      }
      @keyframes slide-from-right {
        0% {
          -webkit-transform: translateX(500px);
          transform: translateX(500px);
        
  opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
         
  opacity: 1;
        }
      }


    
    
   .outline-w {
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #000;
    color: #000;
    opacity: 85;
   }

   

   .text-shadow-pop-top {
    -webkit-animation: text-shadow-pop-top 0.6s both;
            animation: text-shadow-pop-top 0.6s both;
  }

.pp-rotate {
  -webkit-animation: rotate-pp 5s linear infinite;
          animation: rotate-pp 5s linear infinite;
}


@-webkit-keyframes rotate-pp {
0% {
  rotate: 0deg;
}
50% {
rotate: 180deg;
}
100% {
  rotate: 360deg;
  }
}


.font-change {
  -webkit-animation: font-change 2s linear infinite;
          animation: font-change 2s linear infinite;
}


@-webkit-keyframes font-change {
50% {
  font-family: 'Shrikhand';
}

}